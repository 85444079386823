<template>
  <div class="container mt-1">
    <!-- <div>
      <h4>
        <router-link :to="{ name: 'home' }">
          <i class="fas fa-chevron-circle-left fa-lg" />
        </router-link>
        รายละเอียดรายการ
      </h4>
    </div> -->
    <!-- ตัวเลือกหวย -->

    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <div class="mb-2">
        <div class="header-bg-content2 text-center">
          <b-row class="text-info">
            <b-col cols="6">
              <div>
                <button
                  :class="`btn-type3 btnp ${Status === 'NOW' ? 'active' : ''}`"
                  @click="ChangType('NOW')"
                >
                  โพยหวย
                </button>
              </div>
            </b-col>
            <b-col cols="6">
              <div>
                <button
                  :class="`btn-type2 btnp ${
                    Status === 'BEFORE' ? 'active' : ''
                  }`"
                  @click="ChangType('BEFORE')"
                >
                  โพยหวยย้อนหลัง
                </button>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- {{ ListData }} -->
      <div v-if="ListData.length > 0">
        <b-row class="mb-1">
          <b-col cols="6">
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="GetData(Status)"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-col>
          <b-col cols="2" />

          <b-col cols="4">
            <b-form-group
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
                @change="GetData(Status)"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <div
          v-for="(item, index) in ListData"
          :key="item._id"
          class="mb-2"
        >

          <div class="hd_list">
            <b-row>
              <b-col
                cols="6"
                class="flex items-center justify-content-start"
              >
                <div>
                  <a>
                    <img
                      src="@/assets/images/print01.png"
                      alt="ic-menu-user"
                      class="-icon-image mr-1"
                      height="35"
                      @click="PrintList(item._id)"
                    >
                  </a>
                  <small style="color: #fff">เลขที่รายการ #{{
                    Commas(perPage * (currentPage - 1) + (index + 1))
                  }}
                  </small>&nbsp;
                  <span class="pander">รับแทง</span>
                </div>
              </b-col>
              <b-col
                cols="6"
                class="d-flex items-center justify-content-end"
              >
                <div>
                  <small style="color: #fff">{{ item._id }}</small>
                </div>
              </b-col>
            </b-row>
          </div>

          <router-link
            :to="{ name: 'list-huay', params: { ListId: item._id } }"
          >
            <div class="bd_list">
              <b-row>
                <b-col cols="6">
                  <div>
                    <p class="font-medium-1">
                      {{ CalTypeHuay(item.List[0].LottoHead) }} /
                      {{ CalNameDate(item.List[0].round_id) }}
                    </p>
                    <div style="margin-top: -15px">
                      <small>{{ CalDate(item.List[0].created_at) }}</small>
                    </div>
                  </div>
                </b-col>
                <b-col cols="6">
                  <div>
                    <p>
                      เงินเดิมพัน
                      {{
                        Commas(
                          item.List.reduce(
                            (acc, ele) => acc + Number(ele.amount),
                            0,
                          ),
                        )
                      }}
                      ฿
                    </p>
                    <p>
                      ผลชนะทั้งสิ้น
                      {{
                        item.List.some(
                          el =>
                            el.status_result !== 'lose' &&
                            el.status_result !== 'win',
                        )
                          ? '-'
                          : Commas(
                            item.List.reduce(
                              (acc, ele) =>
                                acc +
                                (ele.status_result === 'win'
                                  ? Number(ele.amount) * Number(ele.win_rate)
                                  : 0),
                              0,
                            )
                          )
                      }}
                      <!-- {{
                        item.List.some(
                          el =>
                            el.status_result !== 'lose' &&
                            el.status_result !== 'win',
                        )
                          ? '-'
                          : Commas(
                            item.List.reduce(
                              (acc, ele) =>
                                acc +
                                (ele.status_result === 'win'
                                  ? Number(ele.amount) * Number(ele.win_rate)
                                  : 0),
                              0,
                            ) -
                              item.List.reduce(
                                (acc, ele) => acc + Number(ele.amount),
                                0,
                              ),
                          )
                      }} -->
                      <!-- {{
                        Commas(
                          item.List.reduce(
                            (acc, ele) =>
                              acc + (ele.reward ? Number(ele.reward) : 0),
                            0,
                          ),
                        )
                      }} -->
                      ฿
                    </p>
                  </div>
                </b-col>
              </b-row>
            </div>
          </router-link>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import momenttz from 'moment-timezone'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      show: true,
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 5,
      totalRows: 1,
      Status: 'NOW',
      ListData: [],
      RoundData: [],
      // LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoList: [
        { text: 'กรุณาเลือกหวย', value: null, SubList: null },
        { text: 'หวยรัฐบาล', value: 'THG', SubList: null },
        {
          text: 'ธกส',
          value: 'BAAC',
          SubList: [
            { text: 'หวย ธกส.(BAAC1)', value: 'BAAC1' },
            { text: 'หวย ธกส.(BAAC2)', value: 'BAAC2' },
          ],
        },
      ],
    }
  },
  async mounted() {
    this.LottoList = await this.GetSublist()
    this.ShowHistoryNow()
  },
  methods: {
    PrintList(listid) {
      window.open(`https://khunyingapi.linelotto.vip/api/slip/listid?ListID=${listid}`)
    },
    // eslint-disable-next-line consistent-return
    async GetSublist() {
      const List = []
      try {
        const { data: Res } = await this.$http.get('https://khunyingapi.linelotto.vip/api/LottoList')
        // eslint-disable-next-line
        for (const x in Res) {
          let SubList = null
          if (Res[x].Subhead.length > 0) {
            SubList = []
            // eslint-disable-next-line
            for (const c in Res[x].Subhead) {
              SubList.push(
                { text: Res[x].Subhead[c].name, value: Res[x].Subhead[c].SubHeadID, Data: Res[x].Subhead[c] },
              )
            }
          }
          List.push(
            {
              text: Res[x].name,
              value: Res[x].HeadID,
              SubList,
              Data: Res[x],
            },
          )
        }
        return List
      } catch (e) {
        console.log(e)
      }
    },
    LottoStatus(Head, Sub, Val) {
      if (!Head) return false
      const IndexHead = this.LottoList.findIndex(({ value }) => value === Head)
      if (IndexHead >= 0) {
        if (this.LottoList[IndexHead].SubList && Sub) {
          const IndexSub = this.LottoList[IndexHead].SubList.findIndex(({ value }) => value === Sub)
          if (IndexSub >= 0) {
            return this.LottoList[IndexHead].SubList[IndexSub].Data[Val] === 1
          }
        } else if (!this.LottoList[IndexHead].SubList) {
          return this.LottoList[IndexHead].Data[Val] === 1
        } else {
          return false
        }
      }
      return false
    },
    ChangType(Val) {
      this.Status = Val
      this.currentPage = 1
      this.GetData(Val)
    },
    GetData(Val) {
      this.show = true
      if (Val === 'BEFORE') {
        this.ShowHistoryBefore()
      } else {
        this.ShowHistoryNow()
      }
    },
    async ShowHistoryNow() {
      try {
        const params = {
          page: this.currentPage,
          perpage: this.perPage,
        }
        const { data: response } = await this.$http.get(
          'https://khunyingapi.linelotto.vip/api/history/show',
          { params },
        )
        if (response && response.success === true) {
          const ListData = response.Mes
          ListData.forEach(async (items, index) => {
            // eslint-disable-next-line no-underscore-dangle
            ListData[index].List = await this.GetListDetail(items._id, index)
          })
          this.ListData = ListData
          this.RoundData = response.Round
          this.totalRows = response.total
          this.show = false
        }
      } catch (err) {
        console.log(err)
      }
    },
    async ShowHistoryBefore() {
      try {
        const params = {
          page: this.currentPage,
          perpage: this.perPage,
        }
        const { data: response } = await this.$http.get(
          'https://khunyingapi.linelotto.vip/api/history/showall',
          { params },
        )
        if (response && response.success === true) {
          const ListData = response.Mes
          ListData.forEach(async (items, index) => {
            // eslint-disable-next-line no-underscore-dangle
            ListData[index].List = await this.GetListDetail(items._id, index)
          })
          this.ListData = ListData
          this.RoundData = response.Round
          this.totalRows = response.total
          this.show = false
        }
      } catch (err) {
        console.log(err)
      }
    },
    async GetListDetail(ListId) {
      const params = {
        ListId,
      }
      try {
        const { data: response } = await this.$http.get(
          'https://khunyingapi.linelotto.vip/api/history/ShowByListId',
          { params },
        )
        this.show = false
        if (response && response.success === true) {
          return response.Mes
        }
        return []
      } catch (err) {
        console.log(err)
        return []
      }
    },
    CalTypeHuay(Val) {
      const IndexHead = this.LottoList.findIndex(({ value }) => value === Val)
      // const IndexSub = this.LottoList[IndexHead].SubList.findIndex(({ value }) => value === this.Title.LottoSubHead)
      return this.LottoList[IndexHead].text
    },
    CalDate(Val) {
      return momenttz(Val).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss')
    },
    // eslint-disable-next-line consistent-return
    CalNameDate(Val) {
      if (this.Status === 'NOW') {
        const Index = this.RoundData.findIndex(({ id }) => id === Val)
        if (Index >= 0) {
          return this.RoundData[Index].CloseDateName
        }
      }
      const Index = this.RoundData.findIndex(({ _id }) => _id === Val)
      if (Index >= 0) {
        return this.RoundData[Index].CloseDateName
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
a {
    color: #000000;
    text-decoration: none;
}
.bd_list {
  padding: 10px;
  border-radius: 0 0 10px 10px;
  background-color: rgb(255, 255, 255);
}
.hd_list {
  border-radius: 10px 10px 0 0;
  padding: 10px;
  background-color: rgb(0, 0, 0);
  border-bottom: 1px solid rgb(160, 160, 160);
}
.header-bg-content2 {
  background: linear-gradient(to right, #BF953F, #FCF6BA, #B38728);
  background-color: #fff;
  padding: 10px;
  background-size: cover;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.219);
  border-radius: 10px;
}
.btn-type3 {
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  border-radius: 25px;
  font-weight: 700;
  border: 0px;
}
.btn-type2 {
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  font-weight: 700;
  border-radius: 25px;
  border: 0px;
}
.btnp:hover {
  background-color: #fff;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  background-size: 200% auto;
  transition: 0.5s;
}

.active,
.btnp:focus {
  background-color: #fff;
  color: rgb(0, 0, 0);
}
.pander {
  font-size: 0.8rem;
  padding-right: 5px;
  padding-left: 5px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  background-color: rgb(179, 0, 0);
  border-radius: 5px;
}
</style>
